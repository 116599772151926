import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import _ from "lodash";
import {version as uuidVersion, validate as uuidValidate} from "uuid";

import FigureContainer from "./FigureContainer.js";
import SnappingConnector from "../connectors/connectors/SnappingConnector.js";
import PolygonsPatterns from "./PolygonsPatterns";
import CanvasGrid from "./CanvasGrid";
import CanvasWorldMap from "./CanvasWorldMap";
import {useCanvasBoundingClientRect} from "../../customHooks/useContextCanvasBoundingClientRect";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {usePencilDrawing} from "../../customHooks/useContextPencilDrawing";
import {useLongPolygonDrawing} from "../../customHooks/useContextLongPolygonDrawing";
import useRegularPolygonDrawing from "./customHooks/useRegularPolygonDrawing";
import useObjectSelection from "./customHooks/useObjectSelection";
import usePointFeatureDrawing from "./customHooks/usePointFeatureDrawing";
import useContextMenu from "./customHooks/useContextMenu";
import useRectangleDrawing from "./customHooks/useRectangleDrawing";
import useArrowDrawing from "./customHooks/useArrowDrawing";
import useCooTransformation from "./customHooks/useCooTransformation";
import useCanvasScrolling from "./customHooks/useCanvasScrolling";
import useTextDrawing from "./customHooks/useTextDrawing";
import {useUserViewBox} from "../../customHooks/useContextUserViewBox";
import {useGrid} from "../../customHooks/useContextGrid";
import {isFigureSelected} from '../../utils/figureHierarchy';
import {deleteAt} from "../../../utils";
import {uuidArraySchema} from "../../utils/validators";
import {getRealTarget} from "./customHooks/utils/target";
import Path from "../../utils/path";
import {useWSRoom} from "../../../websocket";
import {useMap} from "../../customHooks/useContextMap";
import {Pointers} from "./Pointers";
import ActionWatcher from "./ActionWatcher";
import {useSelectedFigureContainerEvents} from "./customHooks/useSelectedFigureContainerEvents";
import {useShouldLiftZindexUp} from "../../customHooks/useContextShouldLiftZindexUp";
import {useCurrentProject} from "../../customHooks/useContextCurrentProject";


export const GROUP_LAYOUT = 50;
export const CANVAS_HEIGHT = 1000000;
export const CANVAS_WIDTH = 1000000;


export default function Canvas() {
    const {
        backgroundColor,
        canvasScrollX,
        canvasScrollY,
        figures,
        isContextMenuShow,
        isEditMode,
        isWatchMode,
        scale,
        selectedFigureUuids,
        tool
    } = useReduxData();
    const dispatch = useDispatch();

    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {get: {parameters, icons}} = useIconSet();
    const {
        rectangleSelection,
        selectObject,
        selectSubgroup,
        startRectangleSelection,
        changeSelectionRectangle,
        finishRectangleSelection,
        isRectangleSelectionActive
    } = useObjectSelection();
    const {
        startX,
        startY,
        pencilPath,
        handleMouseEnterOnConnector,
        addSegmentToPathOnMouseDown,
        addSegmentToPathOnMouseMove,
        createFigureFromPencil
    } = usePencilDrawing();
    const {showConfigurationMenu, regularPolygonConfigurationMenu} = useRegularPolygonDrawing();
    const {longPolygonPath, addSegmentToLongPolygonOnMouseDown, configurationMenu} = useLongPolygonDrawing();
    const {createPointFeature} = usePointFeatureDrawing();
    const {createText} = useTextDrawing();
    const {closeContextMenu, openContextMenu} = useContextMenu();
    const {
        startRectangleDrawing,
        changeRectanglePreview,
        finishRectangleDrawing,
        rectanglePreview
    } = useRectangleDrawing();
    const {handleMouseDownOnSelectedFiguresContainer, setInitialFigureMovingPreviewCoo, position} = useSelectedFigureContainerEvents();
    const {startArrowDrawing, changeArrowPreview, finishArrowDrawing, arrowPreview} = useArrowDrawing();
    const {transformXForCanvas, transformYForCanvas, transformXForMap, transformYForMap} = useCooTransformation();
    const {startScroll, scrollCanvas, finishScroll, scrollShiftX, scrollShiftY} = useCanvasScrolling();
    const userViewBox = useUserViewBox();
    const {get: shouldShowGrid} = useGrid();
    const {get: shouldLiftGridZindexUp} = useShouldLiftZindexUp();
    const {get: currentProject} = useCurrentProject();

    const [isScrolled, setIsScrolled] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isRightMouseDown, setIsRightMouseDown] = useState(false);
    const [canRemoveFigureFromSelection, setCanRemoveFigureFromSelection] = useState(false);

    const svgCanvasContainer = useRef(null);
    const svgCanvas = useRef(null);

    const {mapId} = useMap();
    const {send} = useWSRoom(`map/${mapId}/pointers`, {key: ["map", mapId], enabled: !_.isUndefined(mapId)});

    useEffect(() => {
        //update bounding client rect after scale changing and page loading
        //an interval used instead of a timeout, because canvas may take longer, than a second to load
        function setCanvasBoundingClientRect () {
            if (svgCanvas?.current) {
                clearInterval(canvasInterval);
                return canvasBoundingClientRect?.set(svgCanvas.current.getBoundingClientRect());
            }
        }
        const canvasInterval = setInterval(setCanvasBoundingClientRect, 1000);
        window.addEventListener('resize', () => setInterval(setCanvasBoundingClientRect, 1000));
    }, [scrollShiftX, scrollShiftY, canvasScrollX, canvasScrollY, scale]);

    useEffect(() => {
        const viewBox = svgCanvasContainer?.current?.getBoundingClientRect();
        if (viewBox) {
            const newScrollLeft = transformXForCanvas(canvasScrollX) - scrollShiftX - viewBox.width / 2,
                newScrollTop = transformYForCanvas(canvasScrollY) - scrollShiftY - viewBox.height / 2;
            svgCanvasContainer.current.scrollLeft = newScrollLeft;
            svgCanvasContainer.current.scrollTop = newScrollTop;
            userViewBox.set(`${newScrollLeft} ${newScrollTop} ${viewBox.width} ${viewBox.height}`);
        }
    }, [
        canvasScrollX,
        canvasScrollY,
        scale,
        scrollShiftX,
        scrollShiftY,
        svgCanvasContainer?.current?.getBoundingClientRect()
    ]);

    const [figureType, productId, figureIndex] = tool.split("-");
    const iconCursor = figureType === "i" && icons.icons?.[productId]
        ? icons.icons[productId].icons[figureIndex]
        : "";

    const selectSubgroupOnCanvas = event => {
        if (!isWatchMode && isEditMode && uuidValidate(event.target.id) && uuidVersion(event.target.id) === 4) {
            const newSelection = selectSubgroup(event);
            if (uuidArraySchema.isValidSync(newSelection)) {
                dispatch({type: "selectedFigureUuids", value: newSelection});
            }
        }
    };

    const handleContextMenuOnCanvas = event => {
        //don't write code here, because it performs on linux BEFORE on mouse up, but on windows AFTER on mouse up
        event.preventDefault();
    };

    const handleMouseDownOnCanvas = event => {
        if (event.button === 2) { //context menu
            startScroll(event.pageX, event.pageY);
            setIsRightMouseDown(true);
            if (!isWatchMode && tool) {
                if (isEditMode) {
                    setIsMouseDown(false);
                }
            }
        } else if (!isWatchMode && tool) {
            closeContextMenu(event);
            if (!isEditMode) {
                if (figureType === "i") {
                    createPointFeature(event);
                } else if (figureType === "pr") {
                    showConfigurationMenu(event);
                } else if (figureType === "t") {
                    createText(event);
                } else {
                    setIsMouseDown(true);
                    if (["p", "c"].includes(figureType)) {
                        addSegmentToPathOnMouseDown(event);
                    } else if (figureType === "pl") {
                        addSegmentToLongPolygonOnMouseDown(event);
                    } else if (figureType === "r") {
                        startRectangleDrawing(event.pageX, event.pageY);
                    } else if (figureType === "a") {
                        startArrowDrawing(event);
                    }
                }
            } else {
                setIsMouseDown(true);
                if (event.shiftKey) {
                    startRectangleSelection(event);
                } else {
                    const realTarget = getRealTarget(event.target);
                    const targetFigureUuid = realTarget ? realTarget.id.split("|")[0] : "";
                    if (!isFigureSelected(targetFigureUuid, selectedFigureUuids, figures)) {
                        let {type: selectionStatus, payload: selectionPayload} = selectObject(event);
                        if (selectionStatus === "simpleSelection" && uuidArraySchema.isValidSync(selectionPayload)) {
                            if (selectionPayload.length === 1 && figures.get(selectionPayload[0]).get("tool") === "c-s-3") {
                                dispatch({type: "tool", value: "c-s-3"});
                            }
                            dispatch({
                                type: "selectedFigureUuids",
                                value: event.ctrlKey ? selectedFigureUuids.concat(selectionPayload) : selectionPayload
                            });
                            handleMouseDownOnSelectedFiguresContainer(event);
                            setInitialFigureMovingPreviewCoo(event);
                        } else if (selectionStatus === "resetSelection") {
                            dispatch({type: "selectedFigureUuids", value: []});
                        }
                    } else if (event.ctrlKey) {
                        setCanRemoveFigureFromSelection(true);
                    }
                }
            }
        }
    };

    const sendPointer = useCallback(_.throttle((x, y) => send("pointer_move", {x, y}), 500), [send]);

    const handleMouseMoveOnCanvas = event => {
        sendPointer(transformXForMap(event.pageX - canvasBoundingClientRect.get.left),
            transformYForMap(event.pageY - canvasBoundingClientRect.get.top));

        if (isRightMouseDown) {
            if (isContextMenuShow !== "no") { //if context menu was shown at the moment scrolling starts
                dispatch({type: "isContextMenuShow", value: "no"});
            }
            if (!isScrolled) {
                setIsScrolled(true);
            }
            scrollCanvas(event.pageX, event.pageY);
        } else if (tool && !isWatchMode) {
            if (isMouseDown) {
                if (!isEditMode) {
                    if (["c", "p"].includes(figureType)) {
                        addSegmentToPathOnMouseMove(event);
                    } else if (figureType === "r") {
                        changeRectanglePreview(event.pageX, event.pageY);
                    } else if (figureType === "a") {
                        changeArrowPreview(event.pageX, event.pageY);
                    }
                } else if (event.shiftKey) {
                    changeSelectionRectangle(event.pageX, event.pageY);
                }
            }
        }
    };

    const handleMouseUpOnCanvas = event => {
        if (!scrollShiftX && !scrollShiftY) { //right click occur
            setIsRightMouseDown(false);
            finishScroll();
        }
        if (isScrolled) {
            setIsScrolled(false);
            setIsRightMouseDown(false);
            finishScroll();
        } else if (!isWatchMode && tool) {
            if (isEditMode) {
                if (event.button === 2) {
                    openContextMenu(event, selectObject);
                }
                if (event.shiftKey && isRectangleSelectionActive) {
                    let newSelection = finishRectangleSelection();
                    if (uuidArraySchema.isValidSync(newSelection)) {
                        dispatch({type: "selectedFigureUuids", value: newSelection});
                    }
                } else if (position.x === 0 && position.y === 0 && canRemoveFigureFromSelection) {
                    setCanRemoveFigureFromSelection(false);
                    const realTarget = getRealTarget(event.target);
                    const targetFigureUuid = realTarget ? realTarget.id.split("|")[0] : "";
                    if (isFigureSelected(targetFigureUuid, selectedFigureUuids, figures) && position.x === 0 && position.y === 0) {
                        let uuidIndex = selectedFigureUuids.indexOf(targetFigureUuid);
                        if (uuidIndex !== -1 && event.target.className.baseVal !== "transformational-connector" && event.ctrlKey) {
                            dispatch({
                                type: "selectedFigureUuids",
                                value: deleteAt(selectedFigureUuids, uuidIndex)
                            });
                        }
                    }
                }
            } else {
                if (["c", "p"].includes(figureType) && isMouseDown && !event.ctrlKey) {
                    createFigureFromPencil(event, "mouse up");
                } else if (figureType === "r") {
                    finishRectangleDrawing();
                } else if (figureType === "a") {
                    finishArrowDrawing(event);
                }
                if (figureType !== "pl") {
                    dispatch({type: "isEditMode", value: !event.ctrlKey});
                }
            }
            setIsMouseDown(false);
        }
    };

    const updateScrollWithDebounce = _.debounce(val => dispatch({type: "scale", value: val}), 50);

    const handleScroll = event => {
        let standartDelta = Math.min(Math.max(-0.3, event.deltaY), 0.3);
        updateScrollWithDebounce(Math.min(Math.max(scale + standartDelta, 0.01), 5.5));
    };

    return !_.isEmpty(icons) && !_.isEmpty(parameters)
        ? <div className="svg-canvas-container" id="svg-canvas-container" ref={svgCanvasContainer}>
            <ActionWatcher />
            <svg
                id="svg-canvas"
                className="svg-canvas"
                ref={svgCanvas}
                style={{
                    width: scale * CANVAS_WIDTH + "px",
                    height: scale * CANVAS_HEIGHT + "px",
                    cursor: isScrolled
                        ? "move"
                        : !isWatchMode && !isEditMode && tool
                            ? (figureType === "i"
                                ? `url(${iconCursor}) 30 30, auto`
                                : "url(/images/canvas/icon-pencil-cursor.svg) 5 28, auto")
                            : "auto"
                }}
                onDoubleClick={selectSubgroupOnCanvas}
                onContextMenu={handleContextMenuOnCanvas}
                onMouseDown={handleMouseDownOnCanvas}
                onMouseUp={handleMouseUpOnCanvas}
                onMouseMove={handleMouseMoveOnCanvas}
                onWheel={handleScroll}
            >
                <PolygonsPatterns/>
                <rect width={scale * CANVAS_WIDTH + "px"} height={scale * CANVAS_HEIGHT + "px"} fill={backgroundColor}/>
                {currentProject.config?.extraElements?.worldMap && <CanvasWorldMap scale={scale}/>}
                {shouldShowGrid && !shouldLiftGridZindexUp && <CanvasGrid userViewBox={userViewBox.get}/>}
                <FigureContainer handleMouseEnterOnConnector={handleMouseEnterOnConnector} isMouseDown={isMouseDown}/>
                {!isEditMode && <SnappingConnector
                    id="closing-snapping-connector"
                    x={transformXForCanvas(startX)}
                    y={transformYForCanvas(startY)}
                    isMouseDown={isMouseDown}
                    handleMouseEnter={handleMouseEnterOnConnector}
                />}
                <path
                    d={Path.transform(pencilPath, CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2, scale, scale)}
                    stroke="red"
                    fillOpacity="0"
                    style={{pointerEvents: "none"}}
                />
                <path
                    d={Path.transform(longPolygonPath, CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2, scale, scale)}
                    stroke="red"
                    fillOpacity="0"
                    style={{pointerEvents: "none"}}
                />
                <Pointers />
                {rectangleSelection}
                {rectanglePreview}
                {arrowPreview}
                {configurationMenu}
                {regularPolygonConfigurationMenu}
                {shouldShowGrid && shouldLiftGridZindexUp && <CanvasGrid userViewBox={userViewBox.get}/>}
            </svg>
        </div>
        : null;
}
