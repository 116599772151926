import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {ActionCreators} from "redux-undo";
import {saveAs} from "file-saver";
import {toast} from "react-toastify";
import qs from "query-string";
import {validate as validateUuid} from "uuid";

import MapLoader from "../MapLoader";
import {useLocalization} from "../../customHooks/useContextLocalization";
import {useApiClient} from "../../customHooks/useApiClient";
import {useMapFileName} from "./useContextMapFileName";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";
import {useReduxData} from "./useContextReduxData";
import {useCurrentProject} from "./useContextCurrentProject";
import useRectangleDrawing from "../components/canvas/customHooks/useRectangleDrawing";
import updateMap from "../utils/updateMap";
import validateMap from "../utils/validateMap";
import {
    blobToBase64,
    isMapChanged,
    convertStateToSave,
    takePngSnapshotOfTheCanvas,
    getBoundingBoxOfMap
} from "./utils/map";
import {getProjectUuid} from "../utils/currentProject";
import {getAgeOfUser} from "../utils/user";
import {CANVAS_HEIGHT, CANVAS_WIDTH} from "../components/canvas/Canvas";
import {getMap, removeMap} from "../utils/mapAutosave";


const MapContext = React.createContext();


export const MapProvider = ({children}) => {
    const locale = useLocalization();
    const user = useCurrentUser();
    const api = useApiClient();
    const {get: currentProject} = useCurrentProject();
    const {figures, lastAction, scale, state} = useReduxData();
    const mapFileName = useMapFileName();
    const {createRectangleInstantly} = useRectangleDrawing();

    const [mapId, setMapId] = useState();
    const [mapVersionNum, setMapVersionNum] = useState();
    const [mapUuid, setMapUuid] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [dataToLoading, setDataToLoading] = useState([]);
    const [userWantsToSaveMap, setUserWantsToSaveMap] = useState(false);

    const dispatch = useDispatch();

    const isChanged = useMemo(() => isMapChanged(state.get("main").present), [state]);
    const setSaveMapFlag = useCallback(() => setUserWantsToSaveMap(true), [setUserWantsToSaveMap]);

    const {share_token} = qs.parse(window.location.search);

    /*map saving*/

    useEffect(() => { //map sending/saving
        if (userWantsToSaveMap) {
            if (lastAction.type === "saveToServer") {
                saveMapToServer();
                setUserWantsToSaveMap(false);
            } else if (lastAction.type === "saveToComputer") {
                const data = convertStateToSave(state);
                const filename = prompt(locale?.get.studio.header.menu.loadMapTitle, prepareFileNamePattern());
                const blob = new Blob([JSON.stringify(data)], {type: "application/json;charset=utf-8"});
                filename && saveAs(blob, filename + ".json");
                setUserWantsToSaveMap(false);
            }
        }
    }, [lastAction, userWantsToSaveMap, user]);

    const prepareFileNamePattern = () => {
        const now = new Date();
        const city = locale.get.studio.mapFileName.city;
        const gender = user?.gender || locale.get.studio.mapFileName.gender;
        const age = getAgeOfUser(user, now) || locale.get.studio.mapFileName.age;
        const formattedDate = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()]
            .join(".");
        return mapFileName.get || `${city}_${gender}_${age}_${formattedDate}`;
    }

    const saveMapToServer = useCallback(async (type) => {
        try {
            const map = convertStateToSave(state);
            const mapBoundingBox = getBoundingBoxOfMap(figures);
            const blobSnapshot = await takePngSnapshotOfTheCanvas(
                (CANVAS_WIDTH / 2 + mapBoundingBox.x) * scale,
                (CANVAS_HEIGHT / 2 + mapBoundingBox.y) * scale,
                mapBoundingBox.width * scale,
                mapBoundingBox.height * scale
            );
            const data = {
                name: map.main.mapName,
                id: map.main.mapId,
                data: JSON.stringify(map),
                png: (await blobToBase64(blobSnapshot)).slice(22),
                current_project_id: currentProject.id
            };

            const projectUuid = getProjectUuid();
            if (type === "auto") {
                if (mapId && isChanged) {
                    const resp = await api.put(`/api/v2/map/${mapId}`, data);
                    if (resp.data?.id && resp.data?.version) {
                        localStorage.setItem(
                            `server map info ${validateUuid(projectUuid) ? projectUuid : ""}`,
                            `${resp.data.id}-${resp.data.version}`
                        );
                    }
                }
            } else {
                try {
                    let currentUserMemberRole;
                    if (mapId) {
                        const mapMembers = (await api.get(`/api/v2/map/members/?map_id=${mapId}`)).data;
                        for (let i = 0; i < mapMembers.length; i++) {
                            if (mapMembers[i].user_id === user.id) {
                                currentUserMemberRole = mapMembers[i].role;
                                break;
                            }
                        }
                    }
                    const resp = mapId && (currentUserMemberRole === "author" || currentUserMemberRole === "editor"
                            || !share_token)
                        ? await api.put(`/api/v2/map/${mapId}`, data)
                        : await api.post("/api/v2/map/", data);
                    if (resp.data?.id && resp.data?.version) {
                        localStorage.setItem(
                            `server map info ${validateUuid(projectUuid) ? projectUuid : ""}`,
                            `${resp.data.id}-${resp.data.version}`
                        );
                    }

                    setMapId(resp.data.id);
                    setMapVersionNum(resp.data.version);
                    setMapUuid(resp.data.uuid);
                    toast.success(locale.get.studio.header.successMessages.mapSaved);
                } catch (e) {
                    if (e.response.status === 400) {
                        if ("space" in e.response.data.detail) {
                            toast.error(locale.get.studio.errors.noSpaceLeft);
                        }
                    } else if (e.response.status === 404) {
                        if ("project" in e.response.data.detail) {
                            toast.error(locale.get.studio.errors.smthWentWrong);
                        } else if ("map" in e.response.data.detail) {
                            toast.error(locale.get.studio.errors.accessDenied);
                        }
                    } else {
                        toast.error(locale.get.studio.errors.smthWentWrong);
                    }
                }
            }
        } catch (e) {
            if (type !== "auto") {
                toast.warn(e);
            }
        }
    }, [mapId, state, setMapId, setMapVersionNum, api, dispatch, isChanged]);

    /*map loading*/

    const loadMapFromFile = useCallback(async () => {
        const input = document.createElement('input');
        input.type = 'file';

        input.onchange = e => {
            const file = e.target.files[0];

            mapFileName.set(file?.name?.slice(0, -5) || "");

            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = async readerEvent => {
                let data = JSON.parse(readerEvent.target.result + "");
                // console.log(data.actionHistory.map(action => [action.type, action.uuid, action.time].join(" | ")));
                await loadMap(data);
            }
        };
        input.click();
    }, [setMapId]);

    const loadMapFromServer = useCallback(async (id, version) => {
        try {
            if (!isChanged || window.confirm(locale.get.studio.confirmations.changesWillLose)) {
                const resp = await api.get(`/api/v2/map/${id}`);
                const result = await loadMap(resp.data.data);
                if (result !== "failed") {
                    setMapId(id);
                    setMapVersionNum(version);
                    setMapUuid(resp.data.uuid);
                    const projectUuid = getProjectUuid();
                    localStorage.setItem(
                        `server map info ${validateUuid(projectUuid) ? projectUuid : ""}`,
                        `${id}-${version}`
                    );
                }
            }
        } catch (err) {
            if (err.response.status === 404) {
                toast.error(locale?.get.studio.errors.mapNotFound);
            }
        }
    }, [api, locale, isChanged]);

    const loadSharedMap = useCallback(async share_token => {
        try {
            if (!isLoading) {
                const {id, version} = JSON.parse(window.atob(share_token.split(".")[0]));
                const resp = await api.get(`/api/v2/map/${id}`, {params: {share_token}});
                if (resp.data) {
                    await loadMap(resp.data.data);
                    setMapId(id);
                    setMapVersionNum(version);
                    setMapUuid(resp.data.uuid);
                    const projectUuid = getProjectUuid();
                    localStorage.setItem(
                        `server map info ${validateUuid(projectUuid) ? projectUuid : ""}`,
                        `${resp.data.id}-${resp.data.version}`
                    );
                }

            }
        } catch (err) {
            console.error(err)
            if (err.response?.status === 404) {
                toast.error(locale?.get.studio.errors.mapNotFound);
            } else if (err.response?.status === 400) {
                toast.error(locale?.get.studio.errors.tokenIsIncorrect);
            }
        }
    }, [api, locale]);

    const loadMapFromLocalStorage = async () => {
        if (!isLoading) {
            const projectUuid = getProjectUuid();
            const autosavedMap = await getMap(validateUuid(projectUuid) ? projectUuid : "");
            if (autosavedMap) {
                let data = await updateAndValidateMap(autosavedMap);
                if (
                    data
                    && !['saveToServer', 'saveToComputer', 'createMap'].includes(
                        data.main.lastAction.type,
                    )
                ) {
                    setDataToLoading(data);
                    setIsLoading(true);
                }
                const serverMapInfo = localStorage.getItem(
                    `server map info ${validateUuid(projectUuid) ? projectUuid : ""}`
                );
                if (serverMapInfo && serverMapInfo.match(/^\d+-\d+$/)) {
                    const [map_id, version_num] = serverMapInfo.split("-");
                    setMapId(+map_id);
                    setMapVersionNum(+version_num);
                }
            }
        }
    };

    const loadMap = useCallback(async data => {
        data = await updateAndValidateMap(data);
        if (data) {
            setDataToLoading(data);
            setIsLoading(true);
            resetMapMetadata();
        } else {
            return "failed";
        }
    }, [locale, isChanged]);

    const updateAndValidateMap = async mapData => {
        mapData = updateMap(mapData);
        if (mapData === "unsupported") {
            toast.error(locale?.get.studio.errors.obsoleteMapLoadingFailed);
            return undefined;
        }

        mapData = await validateMap(mapData);
        if (mapData === "invalid") {
            toast.error(locale?.get.studio.errors.incorrectMapLoadingFailed);
            return undefined;
        }
        return mapData;
    }

    /*map sharing*/

    const shareMap = useCallback(async (mode="viewer") => {
        if (!mapId) {
            toast.warn(locale?.get.studio.sharing.sendToServerRequired);
            return;
        }
        if (isChanged) {
            await saveMapToServer();
        }
        try {
            const resp = await api.put(`/api/v2/map/${mapId}/share?mode=${mode}`);
            return `${location.href.replace(/(\/#?$)|(\/share.*$)/, "")}/share?share_token=${encodeURIComponent(resp.data)}`;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                toast.warn(locale?.get.studio.sharing.authRequired);
            }
        }
    }, [api, state, mapId, isChanged]);

    /*map deleting*/

    const deleteMapFromServer = useCallback(async id => {
        try {
            let response = await api.delete(`/api/v2/map/${id}`);
            if (mapId === id && response.status === 200 && !response.data["retained versions"]) { //if whole current map deleted
                resetMapMetadata(); //we don't need to remove map itself, only its metadata should be deleted
                const projectUuid = getProjectUuid();
                localStorage.removeItem(`server map info ${validateUuid(projectUuid) ? projectUuid : ""}`);
            }
            return response;
        } catch (e) {
            toast.error(locale?.get.studio.errors.mapCannotBeDeleted);
            return e.response;
        }
    }, [mapId, setMapId, setMapVersionNum, api]);

    /*map clearing*/

    const eraseLocalMapEntirely = useCallback(async menuParamsToSet => {
        eraseLocalMapFromCanvas(menuParamsToSet);

        const projectUuid = getProjectUuid();
        await removeMap(validateUuid(projectUuid) ? projectUuid : "");
        localStorage.removeItem(`server map info ${validateUuid(projectUuid) ? projectUuid : ""}`);

        const searchParams = new URLSearchParams((new URL(location.href)).search);
        searchParams.delete("share_token");
        localStorage.setItem("is map cleared", "yes");
        location.href = location.origin + location.pathname.replace("/share", "") + searchParams.toString();
    }, [dispatch, setMapId, setMapVersionNum, locale]);

    const eraseLocalMapFromCanvas = useCallback(menuParamsToSet => {
        resetMapMetadata();
        dispatch({type: "clearMenuState", menuParamsToSet});
        dispatch({type: "clearState", customName: locale?.get.studio.stdMapName});
        dispatch(ActionCreators.clearHistory());
        mapFileName.set("");
    }, [dispatch, setMapId, setMapVersionNum, locale]);

    const resetMapMetadata = () => {
        setMapId(undefined);
        setMapVersionNum(undefined);
        setMapUuid(undefined);
    };

    const loadMapBackgroundFromFS = useCallback(async (fitType, width, height) => {
        const input = document.createElement('input');
        input.type = 'file';

        input.onchange = e => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = readerEvent => {
                const image_data = readerEvent.target.result;
                const img = document.createElement("img");
                img.onload = () => {
                    if (fitType === "original") {
                        width = img.width;
                        height = img.height;
                    }

                    createRectangleInstantly(image_data, width, height, -width / 2, -height / 2, 1);
                };
                img.src = image_data;
            }
        };
        input.click();
    }, []);

    return <MapContext.Provider value={{
        isChanged,
        setSaveMapFlag,
        mapId,
        mapVersionNum,
        mapUuid,
        saveMapToServer,
        loadMap,
        loadMapFromServer,
        loadSharedMap,
        shareMap,
        deleteMapFromServer,
        loadMapFromFile,
        loadMapFromLocalStorage,
        eraseLocalMapEntirely,
        eraseLocalMapFromCanvas,
        loadMapBackgroundFromFS,
    }}>
        {children}
        {isLoading ? <MapLoader data={dataToLoading} setIsLoading={setIsLoading}/> : null}
    </MapContext.Provider>;
};


export const useMap = () => useContext(MapContext);
