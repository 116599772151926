import getDb from "../../indexedDb";

/**
 * Create transaction and object store within it. Return this object store
 * @param    {String}               storeName      The name of the store to work with
 * @param    {"readwrite"|"read"}   mode           Mode (whether we want read or write)
 * @return   {IDBObjectStore}                      An object store instance
 */
async function createObjectStore(storeName, mode) {
    const db = await getDb(storeName);
    const transaction = db.transaction(storeName, mode);
    transaction.onerror = (event) => console.error(event);
    transaction.onsuccess = (event) => console.log(
        'Transaction to indexed DB completed successfully',
    );

    return transaction.objectStore(storeName);
}


/**
 * Save map to browser storage
 * @typedef  {main: Object, menu: Object, actionHistory: Array}      Map
 * @param    {String}           projectUuid      The uuid of the project (it's just a string)
 * @param    {Map}              mapData          Map converted to a JS Object
 * @return   {undefined}                         Nothing to return
 */
export function saveMap(projectUuid, mapData) {
    const storeName = `auto-saved-map-${projectUuid}`
    getDb(storeName).then(db => {
        const transaction = db.transaction(storeName, "readwrite");
        transaction.onerror = (event) => console.error(event);
        transaction.onsuccess = (event) => console.log(
            'Add: transaction to indexed DB completed',
        );
        let objectStore = transaction.objectStore(storeName);

        const objectStoreRequest = objectStore.put(mapData, "map");
        objectStoreRequest.onerror = (event) => console.error(event);
        objectStoreRequest.onsuccess = (event) => {
            console.log('Add request to indexed DB completed');
        };
    });
}


/**
 * Remove map from browser storage
 * @param    {String}          projectUuid          The uuid of the project (it's just a string)
 * @return   {undefined}                            Nothing to return
 */
export async function removeMap(projectUuid) {
    const storeName = `auto-saved-map-${projectUuid}`
    const objectStore = await createObjectStore(storeName, "readwrite");

    const objectStoreRequest = objectStore.clear();
    objectStoreRequest.onerror = (event) => console.error(event);
    objectStoreRequest.onsuccess = (event) => console.log(
        'Remove request to indexed DB completed',
    );


    localStorage.removeItem(`autosave${projectUuid}`);  // TODO: remove it when all users updated
}


/**
 * Check has browser map saved locally
 * @param    {String}          projectUuid          The uuid of the project (it's just a string)
 * @return   {Boolean}                              True if there is a map, False otherwise
 */
export async function hasMap(projectUuid) {
    const storeName = `auto-saved-map-${projectUuid}`
    const objectStore = await createObjectStore(storeName, "readwrite");

    let mapDataPromise = new Promise(
        resolve => {
            const objectStoreRequest = objectStore.get("map");
            objectStoreRequest.onerror = (event) => console.error(event);
            objectStoreRequest.onsuccess = (event) => {
                console.log('Get (has) request to indexed DB completed');
                resolve(event.target.result);
            }
        }
    );
    let mapData = await mapDataPromise;

    // TODO: remove localStorage usage when all users updated
    return !!mapData || !!localStorage.getItem(`autosave${projectUuid}`);
}


/**
 * Check has browser map saved locally
 * @typedef  {main: Object, menu: Object, actionHistory: Array}      Map
 * @param    {String}          projectUuid          The uuid of the project (it's just a string)
 * @return   {Map|undefined}                        Map (JS object or undefined if there's no map)
 */
export async function getMap(projectUuid) {
    const storeName = `auto-saved-map-${projectUuid}`
    const objectStore = await createObjectStore(storeName, "readwrite");

    let mapDataPromise = new Promise(
        resolve => {
            const objectStoreRequest = objectStore.get("map");
            objectStoreRequest.onerror = (event) => console.error(event);
            objectStoreRequest.onsuccess = (event) => {
                console.log('Get request to indexed DB completed');
                resolve(event.target.result);
            }
        }
    );
    let mapData = await mapDataPromise;
    if (mapData) {
        return mapData;
    }

    // TODO: remove it when all users updated
    let stringifiedMap = localStorage.getItem(`autosave${projectUuid}`);
    if (stringifiedMap) {
        return JSON.parse(stringifiedMap)
    }
    return undefined;
}
