

export default async function getDb(storeName) {
    return new Promise(
        resolve => {
            const request = indexedDB.open("StudioDatabase", 2);
            request.onerror = (event) => {
              console.error(event.target);
              console.error(event.target?.errorCode);
              console.error("Failed to create connection to indexedDB");
            };
            request.onupgradeneeded = function (event) {
                let db = event.target.result;
                console.log("Object Store creation");
                db.createObjectStore(storeName);
            };
            request.onsuccess = (event) => {
              resolve(event.target.result);
              console.log("Connection to the database has successfully been created");
            };
        }
    );
}
