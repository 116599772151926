import {useCallback, useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isUndefined} from "lodash";

import {useMap} from "../../customHooks/useContextMap";
import {useWSRoom, WebSocketContext} from "../../../websocket";


const EDIT_ACTIONS = new Set([
    "addArrow",
    "addRectangle",
    "addPolygon",
    "addPolyline",
    "addPointFeature",
    "addText",
    "addGroup",
    "changeObjectName",
    "changeObjectDescription",
    "changeObjectLink",
    "changeObjectParentUuid",
    "changeFigureColor",
    "changeFigurePoints",
    "changeRectangleSize",
    "changeArrowGeometry",
    "changeFigureDashLength",
    "addFigureEmoji",
    "deleteFigureEmoji",
    "addFigureFlag",
    "deleteFigureFlag",
    "changeFigureFontColor",
    "changeFigureFontSize",
    "changeFigureTransparency",
    "changeFigureLayout",
    "changeFigureVolume",
    "changeFigureThickness",
    "clearState",
    "deleteFigure",
    "disbandGroup",
    "moveFigure",
]);


const useLastAction = (send) => {
    const {wsid} = useContext(WebSocketContext);
    const lastAction = useSelector(state => state.get("main").present.get("lastAction"))

    useEffect(() => {
        const {type, wsid, ...payload} = lastAction;
        if (isUndefined(wsid) && EDIT_ACTIONS.has(type)) {
            send(type, payload);
        }
    }, [lastAction, send, wsid]);
}


export default function ActionWatcher() {
    const {mapId} = useMap();
    const dispatch = useDispatch();

    const callback = useCallback(({action, data, wsid, user_id, avatar}, local_wsid) => {
        dispatch({type: action, wsid, ...data});
    }, [dispatch]);

    const {send} = useWSRoom(`map/${mapId}/canvas`, {callback, key: ["map", mapId], enabled: !isUndefined(mapId)});

    useLastAction(send);

    return null;
}
