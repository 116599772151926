import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {v4 as uuid} from "uuid";


export const WebSocketContext = React.createContext();


export const READY_STATE = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
}


const useWebSocket = (url) => {
    const [wsid, setWsid] = useState();
    const [socket, setSocket] = useState();
    const [state, setState] = useState(READY_STATE.CLOSED);

    const backoff = useRef(500);

    useEffect(() => {
        if (url && wsid) {
            const _socket = new WebSocket(`${url}?wsid=${wsid}`);
            _socket.onopen = () => {
                setState(READY_STATE.OPEN);
                backoff.current = 500;
            }
            _socket.onclose = () => setState(READY_STATE.CLOSED);
            _socket.onerror = () => setState(READY_STATE.CLOSED);
            setSocket(_socket);
            setState(READY_STATE.CONNECTING)

            return () => _socket.close();
        }
    }, [url, wsid]);

    useEffect(() => {
        if (state === READY_STATE.CLOSED) {
            setTimeout(() => setWsid(uuid()), backoff.current);
            if (backoff.current < 30000) backoff.current *= 2;
        }
    }, [url, state]);

    return {socket, state, wsid};
}


export const WebSocketProvider = ({url, children}) => {
    const schema = window.location.protocol == "https:" ? "wss" : "ws";
    const {socket, state, wsid} = useWebSocket(`${schema}://${window.location.host}/api/v2/ws`);

    return <WebSocketContext.Provider value={{socket, state, wsid}}>{children}</WebSocketContext.Provider>;
};


export function useWSRoom(room, {key, callback, enabled=true}) {
    // const api = useApiClient();
    const id = useRef(uuid());
    const {socket, state, wsid} = useContext(WebSocketContext);

    const messageHandler = useMemo(() => callback ? event => {
        const data = JSON.parse(event.data);
        if (data["room"] === room) {
            callback(data, wsid);
        }
    } : null, [room, callback, wsid]);

    useEffect(() => {
        if (enabled && socket && state === READY_STATE.OPEN && messageHandler) {
            socket.addEventListener("message", messageHandler);
            return () => socket.removeEventListener("message", messageHandler)
        }
    }, [socket, state, messageHandler, enabled]);

    useEffect(() => {
        if (enabled && socket && state === READY_STATE.OPEN) {
            socket.send(JSON.stringify({action: "join", room}));
            return () => socket.readySate === 1 && socket.send(JSON.stringify({action: "leave", room}));
        }
    }, [socket, state, room, enabled]);

    const send = useCallback(
        (action, data) => enabled && state === READY_STATE.OPEN && socket.send(JSON.stringify({action, room, data})),
        [socket, state, room, enabled]
    );

    return {send, wsid};
}
