import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import {useModal} from "../../../../../customHooks/useContextModal";
import {useCurrentUser} from "../../../../../customHooks/useContextCurrentUser";
import {useOnboarding} from "../../../../customHooks/useContextOnboarding";
import {useMap} from "../../../../customHooks/useContextMap";
import {useUndoRedo} from "../../../../customHooks/useContextUndoRedo";
import {useDeleteObjects} from "../../../../customHooks/useContextDeleteObjects";
import {useCopyPaste} from "../../../../customHooks/useContextCopyPaste";
import {useIconSet} from "../../../../../customHooks/useContextIconSet";
import useSurvey from "../../../../customHooks/useSurvey";
import useReward from "../../../../customHooks/useReward";
import useAvailableProjects from "./useAvailableProjects";
import {useCurrentProject} from "../../../../customHooks/useContextCurrentProject";
import {useShouldHideToolbar} from "../../../../customHooks/useContextShouldHideToolbar";
import {useShouldLiftZindexUp} from "../../../../customHooks/useContextShouldLiftZindexUp";
import {getFirstToolFromTabSet} from "../../../../utils/tabSet";


const menuItems = [
    {
        key: "file",
        isExtended: false, //нужно ли рендерить место для hotKeys
        items: [
            {
                key: "newMap",
                enabled: true
            },
            {
                key: "myMaps",
                enabled: true
            },
            {
                key: "send",
                enabled: true
            },
            {
                key: "load",
                enabled: true
            },
            {
                key: "save",
                enabled: true
            }
        ]
    },
    {
        key: "edit",
        isExtended: true,
        items: [
            {
                key: "undo",
                hotkey: "Ctrl+Z",
                enabled: (props) => !props.isWatchMode && props.isTherePast
            },
            {
                key: "redo",
                hotkey: "Ctrl+Shift+Z",
                enabled: (props) => !props.isWatchMode && props.isThereFuture
            },
            {
                key: "copy",
                hotkey: "Ctrl+C",
                enabled: (props) => !props.isWatchMode && props.isThereSelectedFigures
            },
            {
                key: "paste",
                hotkey: "Ctrl+V",
                enabled: (props) => !props.isWatchMode && props.isThereClipboardContent
            },
            {
                key: "delete",
                hotkey: "Delete",
                enabled: (props) => !props.isWatchMode && props.isThereSelectedFigures
            },
            {
                key: "toolbarOn",
                enabled: true
            },
            {
                key: "liftGridZindexUp",
                enabled: true
            }
        ]
    },
    {
        key: "onboarding",
        isExtended: false,
        items: []
    },
    {
        key: "projects",
        isExtended: false,
        items: []
    },
    {
        key: "store",
        isExtended: false,
        items: []
    }
];


export default function () {
    const locale = useLocalization();
    const modal = useModal();
    const user = useCurrentUser();
    const onboarding = useOnboarding();
    const map = useMap();
    const {undo, redo} = useUndoRedo();
    const {deleteObjects} = useDeleteObjects();
    const {copyObjects, pasteObjects} = useCopyPaste();
    const {get: {tabSet}} = useIconSet();
    const {tryToShowSurvey} = useSurvey();
    const {tryToShowReward} = useReward();
    const history = useHistory();
    const availableProjects = useAvailableProjects();
    const {get: currentProject} = useCurrentProject();
    const {resetData} = useIconSet();
    const {get: shouldHideToolbar, set: setShouldHideToolbar} = useShouldHideToolbar();
    const {get: shouldLiftGridZindexUp, set: setShouldLiftGridZindexUp} = useShouldLiftZindexUp();

    const dispatch = useDispatch();

    useEffect(() => {  //add extra menu items for admins and special users
        if (menuItems[0].items.length === 5 && (
            (user?.email && process.env.REACT_APP_BACKGROUND_ALLOWED_EMAILS?.split(",")?.includes(user?.email))
            || [1, 2].includes(user?.role)
            || (currentProject.config?.extraElements || {}).extendedFileMenu === true
        )) {
            menuItems[0].items = menuItems[0].items.concat([
                {
                    key: "usersMaps",
                    enabled: true
                },
                {
                    key: "loadBackground",
                    enabled: true
                },
                {
                    key: "clearBackground",
                    enabled: true
                }
            ]);
        }
    }, [user, currentProject, menuItems]);

    useEffect(() => {  //change toolbar item
        if (shouldHideToolbar !== undefined) {
            const editKeys = menuItems[1].items.map(item => item.key);
            const [toolbarKey, newToolbarKey] = editKeys.includes("toolbarOn")
                ? ["toolbarOn", "toolbarOff"]
                : ["toolbarOff", "toolbarOn"];
            const toolbarKeyIndex = editKeys.indexOf(toolbarKey);
            menuItems[1].items[toolbarKeyIndex] = {key: newToolbarKey, enabled: true};
        }
    }, [shouldHideToolbar]);

    useEffect(() => {  //change toolbar item
        if (shouldLiftGridZindexUp !== undefined) {
            const editTabKeys = menuItems[1].items.map(item => item.key);
            const [toolbarKey, newToolbarKey] = editTabKeys.includes("liftGridZindexUp")
                ? ["liftGridZindexUp", "liftGridZindexDown"]
                : ["liftGridZindexDown", "liftGridZindexUp"];
            const toolbarKeyIndex = editTabKeys.indexOf(toolbarKey);
            menuItems[1].items[toolbarKeyIndex] = {key: newToolbarKey, enabled: true};
        }
    }, [shouldLiftGridZindexUp]);

    useEffect(() => {
        const handlers = {
            file: {
                newMap: () => (
                    !map.isChanged || window.confirm(locale.get.studio.confirmations.changesWillLose)
                ) && map.eraseLocalMapEntirely({tool: getFirstToolFromTabSet(tabSet), color: "#000000"}),
                myMaps: () => modal.set(user ? "my_maps" : "signin"),
                usersMaps: () => modal.set(user ? "users_maps" : "signin"),
                send: async () => {
                    if (user) {
                        dispatch({type: "saveToServer"});
                        map.setSaveMapFlag();
                        await tryToShowSurvey();
                        tryToShowReward();
                    } else {
                        modal.set("signin");
                    }
                },
                load: () => {
                    if (!map.isChanged || window.confirm(locale.get.studio.confirmations.changesWillLose)) {
                        dispatch({
                            type: "clearMenuState",
                            menuParamsToSet: {tool: getFirstToolFromTabSet(tabSet), color: "#000000"}
                        });
                        dispatch({type: "clearState"});
                        map.loadMapFromFile();
                    }
                },
                save: () => {
                    dispatch({type: "saveToComputer"});
                    map.setSaveMapFlag();
                },
                loadBackground: () => modal.set("background_loading"), //TODO: rewrite it to loading of dynamic image
                // export: () => { //don't used yet
                //     // const data = document.getElementById("svg-canvas-container").innerHTML;
                //     // let s = new XMLSerializer();
                //     // let serialized = s.serializeToString(data);
                //     // const blob = new Blob([serialized], {type: "application/text;charset=utf-8"});
                //     // const filename = prompt(locale?.get.studio.header.menu.loadMapTitle);
                //     // filename && saveAs(blob, filename + ".txt");
                // },
                // exportTexts: () => {
                //     const emojisToString = emojis => {
                //         let names = emojis.map(em => `${em.emoji.native}`);
                //         return names.join(";");
                //     };
                //
                //     let rawFigures = state.get("main").present.get("figures").toJS();
                //     let readyMadeData = Object.keys(rawFigures).map(uuid => rawFigures[uuid]);
                //
                //     //entry format: name,figureName,figureDescription;figureEmojis,geoObject,tool,creationTime,color,fontColor,volume,opacity,sex,age,mapId
                //     readyMadeData = readyMadeData.map(el => {
                //         let description = `${el.description};${emojisToString(el.emoji || [])}`;
                //         if (description === ";") {
                //             description = ""
                //         }
                //         if (description.length > 0 && description[0] === ";") {
                //             description = description.slice(1);
                //         }
                //         if (description.length > 0 && description[description.length - 1] === ";") {
                //             description = description.slice(0, description.length - 1);
                //         }
                //
                //         return '"' + [
                //             user?.fullname || user?.username || "",
                //             el.name,
                //             description,
                //             "", //geoObject
                //             parameters[el.tool]?.title?.[locale?.get.name] || "",
                //             el.orderIndex - startTime,
                //             el.color,
                //             el.fontColor || "",
                //             // el.fontSize || el.dashLength || "",
                //             el.volume || "",
                //             el.transparency,
                //             user?.gender || "",
                //             getAgeOfUser(user) || "",
                //             mapId,
                //         ].join('","') + '"';
                //     });
                //
                //     const now = new Date();
                //     const gender = user?.gender || locale.get.studio.mapFileName.gender;
                //     const age = getAgeOfUser(user, now) || locale.get.studio.mapFileName.age;
                //     const formattedDate = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()]
                //         .join(".");
                //     const mapNamePattern = mapFileName.get || `${gender}_${age}_${formattedDate}`;
                //
                //     const filename = prompt(locale?.get.studio.header.menu.loadMapTitle, mapNamePattern);
                //     const blob = new Blob([readyMadeData.join("\r\n")], {type: "data:text/csv;charset=utf-8"});
                //     filename && saveAs(blob, filename + ".csv");
                // },
                // validate: () => dispatch({type: "isValidationAnimationShow", value: true})
            },
            edit: {
                undo,
                redo,
                copy: () => copyObjects({x: 0, y: 0}),
                paste: () => pasteObjects({x: 0, y: 0}),
                delete: deleteObjects,
                toolbarOff: () => setShouldHideToolbar(false),
                toolbarOn: () => setShouldHideToolbar(true),
                liftGridZindexUp: () => setShouldLiftGridZindexUp(true),
                liftGridZindexDown: () => setShouldLiftGridZindexUp(false),
            },
            onboarding: {
                self: () => onboarding.setShouldShowOnboarding(true)
            },
            projects: {},
            store: {
                self: () => window.open("/store", "_blank")
            }
        };

        menuItems.forEach(item => {
            if (handlers[item.key].self) {
                item.onClick = handlers[item.key].self;
            } else {
                item.items.forEach(subItem => {
                    if (handlers[item.key][subItem.key]) {
                        subItem.onClick = handlers[item.key][subItem.key];
                    }
                });
            }
        });
    });

    useEffect(() => { //add all dynamic items
        const projectSubItemList = [];
        Object.keys(availableProjects).forEach(projectName => {
            if ("/" + availableProjects[projectName].trim() !== location.pathname) {
                projectSubItemList.push({
                    key: projectName,
                    enabled: true,
                    onClick: () => {
                        map.eraseLocalMapFromCanvas();
                        resetData();
                        history.push("/" + availableProjects[projectName]);
                    }
                });
            }
        });
        menuItems[3].items = projectSubItemList;
    }, [availableProjects, history, currentProject, resetData]); // location.pathname depends on currentResearch

    return menuItems;
}
